<main>
    <header class="page-header header container-fluid">
        <div class="overlay"></div>

        <div class="description">
            <h1 class="animate__animated animate__headShake">Welcome to DeathMatch</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum quam odio, quis placerat ante luctus eu. Sed aliquet dolor id sapien rutrum, id vulputate quam iaculis. Suspendisse consectetur mi id libero fringilla, in pharetra sem ullamcorper.</p>
        </div>



        <script>
$(document).ready(function () {
    $('.header').height($(window).height());
})
        </script>


    </header>

    <section class="background-custom" >

        <div class="container features">

            <div class="row">

     


                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <h3 class="feature-title">Gaming</h3>
                    <div class="circle col-center-blockanimated" animateOnScroll animationName="animate__animated animate__pulse">

                        <div class="d-none d-md-flex">
                             <i class="fas fa-headset fa-10x col-center-block green"></i>
                        </div>
                        <div class="d-md-none">
                            <i class="fas fa-headset fa-5x green"></i>
                        </div>
                    </div>
                    <p class="light-grey-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum quam odio, quis placerat ante luctus eu. Sed aliquet dolor id sapien rutrum, id vulputate quam iaculis.</p>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <h3 class="feature-title">Pizza</h3>
                    <div class="circle col-center-blockanimated" animateOnScroll animationName="animate__animated animate__pulse">

                        <div class="d-none d-md-flex">
                            <i class="fas fa-pizza-slice fa-10x col-center-block green"></i>
                        </div>
                        <div class="d-md-none">
                            <i class="fas fa-pizza-slice fa-5x green"></i>
                        </div>

                    </div>
                    <p class="light-grey-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum quam odio, quis placerat ante luctus eu. Sed aliquet dolor id sapien rutrum, id vulputate quam iaculis.</p>
                </div>


                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <h3 class="feature-title">Virtual Reality</h3>
                    <div class="circle col-center-block animated" animateOnScroll animationName="animate__animated animate__pulse">

                        <div class="d-none d-md-flex">
                            <i class="fas fa-user-astronaut fa-10x col-center-block green"></i>
                        </div>
                        <div class="d-md-none">
                            <i class="fas fa-user-astronaut fa-5x green" ></i>
                        </div>

                    </div>
                    <p class="light-grey-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum quam odio, quis placerat ante luctus eu. Sed aliquet dolor id sapien rutrum, id vulputate quam iaculis.</p>
                </div>

            </div>

        </div>
   

    </section>



</main>
