

<nav class="navbar navbar-fixed-top navbar-dark bg-dark">
  <div class="container">
      <div class="navbar-brand">
        <a href="https://twitter.com/cafematch" class="fab fa-discord fa-2x right-margin hvr-grow"></a>
        <a href="https://twitter.com/cafematch" class="fab fa-instagram fa-2x right-margin hvr-grow "></a>
        <a href="https://twitter.com/cafematch" class="fab fa-facebook fa-2x right-margin hvr-grow"></a>
        <a href="https://twitter.com/cafematch" class="fab fa-twitter fa-2x right-margin hvr-grow"></a>
        <a href="https://www.snapchat.com" class="fab fa-snapchat fa-2x right-margin hvr-grow"></a>
      </div>
      <ul class="nav">
          <li class="nav-item"><a class="nav-link hvr-grow" routerLinkActive="active" routerLink="/about">About</a></li>
          <li class="nav-item"><a class="nav-link hvr-grow" routerLinkActive="active" routerLink="/esports">Esports</a></li>
          <li class="nav-item"><a class="nav-link hvr-grow" routerLinkActive="active" routerLink="/pricing">Pricing</a></li>
       </ul>
   
  </div>
</nav>

<router-outlet></router-outlet>


<footer class="page-footer">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12">
              <h6 class="text-uppercase font-weight-bold">Additional Information</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum quam odio, quis placerat ante luctus eu. Sed aliquet dolor id sapien rutrum, id vulputate quam iaculis.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque interdum quam odio, quis placerat ante luctus eu. Sed aliquet dolor id sapien rutrum, id vulputate quam iaculis.</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
              <h6 class="text-uppercase font-weight-bold">Contact</h6>
              <p>
                  10 XXXXXXXX, Sleaford, Lincolnshire
                  <br />info<span>@</span>DeathMatchCafe.com
                  <br />+ 01 234 567 88
                  <br />+ 01 234 567 89
              </p>
          </div>
      </div>
  </div>
  <div class="footer-copyright text-center"><i class="far fa-copyright fa-1x green" ></i> 2020 Copyright: DeathMatchCafe.com</div>
</footer>

