import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EsportsComponent } from './esports/esports.component';
import { AboutComponent } from './about/about.component';
import { PricingComponent } from './pricing/pricing.component';

const routes: Routes = [  { path: '', redirectTo: '/about', pathMatch: 'full' },
                          { path: 'esports', component: EsportsComponent },
                          { path: 'pricing', component: PricingComponent },
                          { path: 'about', component: AboutComponent },];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
